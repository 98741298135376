.wallets-page__accent-bar {
  height: 21.5rem;
  margin-bottom: -21.5rem;
  background-image: var(--accent-bar__gradient);
  width: 100%;
  flex-shrink: 0;
}

.wallets-page__container {
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1 0 auto;
}

.wallets-page__footer {
  margin-top: 8rem;
  flex-shrink: 0;
}

.wallets-page__wallet-list-container {
  box-shadow: var(--component__box-shadow);
  overflow: hidden;
  border-radius: 1rem;
}

.wallets-page__wallet-list-container-header {
  padding: 3rem 3rem 3.6rem;
  font-size: 2.4rem;
  color: white !important;
}

.interest-earned-container {
  background-color: #222630;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.interest-earned-message {
  padding: 2rem 2rem 2rem 4rem;

}

.interest-earned-message h4 {
  /* color: black; */
}

.transfer-request-notification-list__empty {
  color: white !important;
}

.interest-amount {
  font-weight: 700;
  font-size: 2rem;
  padding-left: 1rem;
}

.buy-sto-container {
  border-radius: 1rem;
  background-color: #273e7a;
  padding: 2rem 2rem 2rem 2rem;
  /* margin-bottom: 4rem; */
}

.buy-sto-container img {
  width: 4rem;
  margin-right: 1.5rem;
}

.buy-sto-btn {
  padding: 1.25rem;
  font-weight: bold;
  background: #2aa2ee;
  color: white;
  border-radius: 5px;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
}

.buy-sto-btn.disabled {
  opacity: 0.6;
}

.buy-sto-btn:hover {
  background: #2aa2eecf;
  color: white;
}

.wallet-row-list::-webkit-scrollbar {
  width: 3px;
}

.wallet-row-list::-webkit-scrollbar-track {
  background: #888888;
}

.wallet-row-list::-webkit-scrollbar-thumb {
  background: #485a5a;
}

.balanceMsg div {
  font-size: 1.2rem !important;
  color: red;
  font-weight: 700;
  line-height: normal;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallets-page__footer {
    margin-top: 3.4rem;
  }
  .buy-sto-btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .interest-earned-message {
    padding: 2rem;
  }
  .wallet-card__icon img {
    margin-top: 1rem;
  }
  .wallet-card__activity-link-row {
    padding: 1rem 2.5rem;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .wallet-row__activity-link {
    width: 100% !important;
  }
}