.send-form {
  padding: 3.7rem 3rem;
  font-size: 1.4rem;
}

.send-form__send-to-item {
  margin-right: 2rem;
  cursor: pointer;
}

.send-form__send-to-item--selected {
  color: var(--font__color-primary);
}

.send-form__btn {
  border-radius: 5px;
  width: 100%;
  height: 5rem;
  font-size: 1.4rem;
}

.send-form__source {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border: 1px solid var(--border__color);
  box-sizing: border-box;
  margin-bottom: 3.6rem;
  border-radius: 4px;
  background-color: var(--secondary__bg-color);
}

.send-form__source-item {
  font-size: 1.4rem;
  height: 3.6rem;
  flex: 1 1;
  line-height: 3.6rem;
  text-align: center;
  cursor: pointer;
  background: var(--secondary__bg-color);
  color: var(--font__color-secondary);
  border-radius: 2px;
  margin: 0.4rem;
}

.send-form__source-item-with-border {
  flex: 1 1;
  border-right: 1px solid var(--border__color);
}

.send-form__source-item--selected,
.send-form__source-item:hover {
  background: var(--success__bg-color);
  color: var(--font__color-primary);
}

.send-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--component__border-color);
}

.send-form__label-text {
  color: var(--font__color-secondary);
  margin-bottom: 1.2rem;
}

.send-form__label {
  display: none;
}

.send-form__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
}

.send-form__with-label {
  padding-left: 6rem;
}

.send-form__info-items {
  margin-bottom: 2rem;
}

.send-form__lwt-container {
  display: flex;
  flex-direction: row;
  min-height: 3rem;
  border-bottom: 1px solid var(--border__color);
  align-items: center;
  font-size: 1.2rem;
}

.send-form__lwt-label {
  margin: 0;
}

.send-form__input-wrapper {
  margin-bottom: 3.6rem;
}
