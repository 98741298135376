.fiat-deposit-form__title,
.cryptowallet-withdraw__title {
  color: var(--text-primary);
  padding-bottom: 2rem;
}

.fiat-deposit-form__body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.cryptowallet-withdraw__address-container {
  display: flex;
  padding: 2rem;
  background: var(--primary__bg-color);
  justify-content: space-between;
}

.cryptowallet-withdraw__qrcode {
  margin-right: 2rem;
}

.fiat-deposit-form__select-wrapper,
.fiat-deposit-form__input-wrapper {
  padding-bottom: 2.6rem;
}

.fiat-deposit-form__select,
.fiat-deposit-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  min-width: 20rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--border__color);
}

.inline__info {
  width: 100%;
  text-align: right;
}

.fiat-deposit-form__close__btn {
  margin-top: 1rem;
}

.fiat-deposit-form__form-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fiat-deposit-form__select-wrapper,
.fiat-deposit-form__input-wrapper {
  margin: 1rem;
  text-align: left;
}

.fiat-deposit-form__select-label,
.fiat-deposit-form__label {
  margin: 0 0 1.2rem;
  font-size: 1.4rem;
}

.fiat-deposit-form__input-box {
  display: block;
  width: 100%;
}

.fiat-deposit-form__success {
  display: flex;
  height: 10rem;
  align-items: center;
  justify-content: center;
}

.fiat-deposit-form__error {
  padding: 2rem;
}

.fiat-deposit-form__btn {
  width: calc(100% - 2rem);
  margin: 1rem;
  height: 5rem;
  border-radius: 0.3rem;
  font-size: 1.4rem;
}

.fiat-deposit-form__template-body,
.fiat-deposit-form__template-footer {
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
}

.fiat-deposit-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color-secondary);
  position: initial;
  margin-left: 0;
}

.fiat-deposit-form__spinner-circle {
  margin: 0;
}

.fiat-deposit-form__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}

.fiat-deposit-form__with-label {
  padding-left: 6rem;
}

.fiat-deposit-form__bank-info-label {
  padding: 0 1rem 1.5rem;
  color: var(--font__color-secondary-light);
  font-size: 1.4rem;
}

.fiat-deposit-form__bank-info {
  border: 1px solid var(--border__color);
  padding: 1.5rem 2.3rem;
  margin: 1rem 1rem 4rem;
}

.fiat-deposit-form__lwt-container {
  justify-content: unset;
}

.fiat-deposit-form__lwt-container--template-info {
  justify-content: space-between;
}

.fiat-deposit-form__lwt-label {
  min-width: 15rem;
}

.fiat-deposit-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
  position: initial;
  margin-left: 0;
}

.fiat-deposit-form__spinner-circle {
  margin: 0;
}

.fiat-deposit-form__frame {
  padding: 1rem 2.2rem 0 2.2rem;
  display: flex;
  flex-direction: column;
}

.fiat-deposit-form__fields {
  height: 100%;
}

/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .fiat-sidepane__main-form {
    width: calc(100% - 12rem);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .fiat-sidepane__main-form {
    width: unset;
  }
}
