.receive-form__btn {
  border-radius: 0.3rem;
  width: 100%;
  height: 5rem;
  font-size: 1.4rem;
}

.receive-form__amount-label {
  color: var(--font__color-primary);
}

.receive-form__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  background: var(--secondary__bg-color);
  border: 1px solid var(--component__border-color);
}

.receive-form__label-text {
  color: var(--font__color-secondary);
  margin-bottom: 1.2rem;
}

.receive-form__label {
  display: none;
}

.receive-form__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
  margin-top: 0.1rem;
}

.receive-form__with-label {
  padding-left: 6rem;
}

.receive-form__input-wrapper {
  margin-bottom: 3.6rem;
}


/* MEDIA QUERIES */


/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .receive-form{
    margin: 0 2.4rem 4.3rem;
  }
}