.standalone-layout__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  background: var(--standalone-form__wrapper-bg-image);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-size: cover!important;
}

.standalone-layout__logo {
  margin: 2.8rem;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--standalone-form__logo-color);
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 576px) {
  .standalone-layout__logo {
    margin: auto;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 0;
  }
}

