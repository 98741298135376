.purchase-form-iframe {
    min-width: 100%;
    min-height: 100vh;
}

.verification-message {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

h2 {
    font-size: calc(1.325rem + .9vw) !important;
}

.full-height {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}