.send-receive-confirm-modal {
  min-width: 40rem;
  border-radius: 1rem;
}

.send-receive-confirm-modal__header {
  border-bottom: 2px solid var(--tab__border-color--selected);
}

.send-receive-confirm-modal__content {
  padding: 2.8rem 2.8rem 6rem 2.8rem;
}

.send-receive-confirm-modal__title {
  font-size: 1.4rem;
  color: var(--font__color-primary);
  margin-bottom: 2rem;
}

.send-receive-confirm-modal__lwt-container {
  display: flex;
  flex-direction: row;
  min-height: 2.7rem;
  border-top: 1px solid var(--border__color);
  align-items: center;
  padding: 1rem 0;
  justify-content: unset;
}

.send-receive-confirm-modal__lwt-container:last-child {
  border-bottom: none;
}

.send-receive-confirm-modal__detail:first-child {
  border-top: 1px solid var(--border__color);
}

.send-receive-confirm-modal__lwt-label {
  font-size: 1.2rem;
  flex: 0 0 11rem;
  color: var(--font__color-secondary);
  margin: 0;
}

.send-receive-confirm-modal__lwt-text {
  padding-left: 1rem;
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  color: var(--font__color-primary);
  /* word-break: break-word; */
}

.send-receive-confirm-modal__footer {
  margin-right: 2.5rem;
}

.send-receive-confirm-modal__btn {
  width: 100%;
  align-items: center;
  border-radius: 0.5rem;
  height: 5rem;
  justify-content: center;
  font-size: 1.4rem;
  background: var(--success__bg-color);
}

.send-receive-confirm-modal__close-icon {
  color: var(--font__color-primary);
  padding: 1.4rem 1.1rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .send-receive-confirm-modal {
    min-width: 32rem;
    max-width: 32rem;
  }
}

