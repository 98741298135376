.withdraw-form {
  width: 50rem;
}

.withdraw-form__title,
.cryptowallet-withdraw__title {
  color: var(--font__color-primary);
  padding-bottom: 2rem;
}

.cryptowallet-withdraw__address-container {
  display: flex;
  padding: 2rem;
  background: var(--primary__bg-color);
  justify-content: space-between;
  /* font-size: 0.8rem; */
}

.cryptowallet-withdraw__qrcode {
  margin-right: 2rem;
}

.withdraw-form__select-wrapper,
.withdraw-form__input-wrapper {
  display: inline-flex;
  align-items: center;
  margin: 0;
}

.withdraw-form__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.withdraw-form__input-wrapper,
.withdraw-form__select-wrapper {
  padding-bottom: 2.4rem;
}

.withdraw-form__input-wrapper:last-child {
  padding-bottom: 0;
}

.inline__info {
  width: 100%;
  text-align: right;
}

.withdraw-form__close__btn {
  margin-top: 1rem;
}

.withdraw-form__form-body {
  width: 100%;
  min-height: 30rem;
}

.withdraw-form__select-label,
.withdraw-form__label {
  width: 15rem;
  margin: 0;
  justify-content: flex-start;
}

.withdraw-form__input,
.withdraw-form__select {
  width: 22rem;
}

.withdraw-form__btn {
  width: 20rem;
}

.withdraw-form__section,
.withdraw-form__error-text {
  margin-top: 3.2rem;
  padding-top: 3.2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
}

.withdraw-form__lwt-container {
  width: 37rem;
  padding-bottom: 1rem;
}

.withdraw-form__lwt-container:last-child {
  padding-bottom: 0;
}

.withdraw-form__currency {
  letter-spacing: 1px;
  color: var(--font__color-primary);
}

.withdraw-form__spinner-container {
  margin-top: 2rem;
  padding-top: 2rem;
  transition: none;
  border-top: 1px solid var(--border__color);
  position: initial;
  margin-left: 0;
}

.withdraw-form__spinner-circle {
  margin: 0;
}

.withdraw-form__input-box {
  flex-direction: column;
}

.withdraw-form__error-message {
  display: block;
  color: var(--danger__color);
  padding-bottom: 2.4rem;
}

.withdraw-form__verification-required-text {
  width: 20rem;
}

.withdraw-form__trustpay-form {
  color: rgb(var(--th-settings__trustpay-form-font-color));
}

.withdraw-form__max__btn {
  display: inline-block;
  margin-left: 2rem;
  position: absolute;
  height: 2.9rem;
  line-height: 2.9rem;
}