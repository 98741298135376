.ap-checkbox__checkbox {
  display: block;
  position: relative;
  white-space: nowrap;
}

.ap-checkbox__icon {
  position: absolute;
  left: 1px;
  top: -1px;
  color: var(--input__checkbox-icon-color);
  z-index: 1;
}

.ap-checkbox__checkbox--radio {
  display: inline;
  padding-left: 0;
}

.ap-checkbox__checkbox input[type='checkbox'] {
  display: none;
}

.ap-checkbox__checkbox input[type='checkbox']:checked ~ label,
.ap-checkbox__checkbox label:hover {
  color: var(--font__color-primary);
}

.ap-checkbox__checkbox label {
  display: inline-block;
  text-align: left;
  color: var(--checkbox__label-color);
  line-height: 2rem;
  cursor: pointer;
  padding-left: 3.5rem;
}

.ap-checkbox__checkbox label:after {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  border: 1px solid var(--input__border-color);
  position: absolute;
  top: -1px;
  left: 0;
  transition: all 0.25s;
}

.ap-checkbox__checkbox input[type='checkbox']:checked ~ label:after,
.ap-checkbox__checkbox label:hover:after {
  border: 1px solid var(--checkbox__label-color--hover);
}

.ap-checkbox__checkbox--inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.ap-checkbox__checkbox--inline label {
  padding-left: 3rem;
}

.ap-checkbox__checkbox--inline label:after,
.ap-checkbox__checkbox--inline .ap-checkbox__icon {
  top: 0;
}

.ap-checkbox__checkbox-error {
  color: var(--input-checkbox-error);
  display: inline-block;
  padding-top: 0.5rem;
}

.ap-checkbox__form-text-muted {
  display: inline-block;
  color: var(--font__color-secondary);
  padding-top: 0.5rem;
}
