.fiat-deposit-modal {
  display: flex;
  flex-direction: column;
  min-width: 35rem;
  border-radius: 1rem;
}

.fiat-deposit-modal__content {
  padding: 2.8rem;
}

.fiat-deposit-modal__bank-info {
  padding: 1.5rem 2.3rem;
  margin: 2rem 0;
  border: 1px solid var(--border__color);
}

.fiat-deposit-modal__lwt-container {
  height: 3.2rem;
  line-height: 3.2rem;
  border-top: 1px solid var(--border__color);
  justify-content: unset;
  font-size: 1.2rem;
}

.fiat-deposit-modal__lwt-label {
  min-width: 13.5rem;
}

.fiat-deposit-modal__lwt-container--bank-info {
  border-top: none;
  height: 2rem;
  line-height: 1.25rem;
  letter-spacing: 0.1px;
}

.fiat-deposit-modal__information {
  border-top: none;
  width: 35rem;
}

.fiat-deposit-modal__information-text {
  font-style: italic;
  color: var(--font__color-secondary-light);
  letter-spacing: 0.1px;
  font-size: 1.2rem;
  padding-bottom: 2rem;
}

.fiat-deposit-modal__information-text:last-child {
  padding-bottom: 0;
}

.fiat-deposit-modal__lwt-container--bank-info:first-child {
  margin-bottom: 1rem;
}

.fiat-deposit-modal__lwt-label--bank-info {
  min-width: 15rem;
  height: 1.5rem;
}

.fiat-deposit-modal__lwt-text {
  color: var(--text-color);
}

.fiat-deposit-modal__icon {
  margin-right: 1.5rem;
  width: 3.6rem;
  height: 3.6rem;
}

.fiat-deposit-modal__header {
  border-bottom: 2px solid var(--tab__border-color--selected);
  padding: 1.6rem;
}

.fiat-deposit-modal__header-content {
  display: flex;
  align-items: center;
}

.fiat-deposit-modal__header-title {
  font-size: 2rem;
  color: var(--font__color-primary);
}

.fiat-deposit-modal__footer {
  margin-right: 2.5rem;
}

.fiat-deposit-modal__btn {
  width: 100%;
  align-items: center;
  border-radius: 0.5rem;
  background: var(--success__bg-color);
  font-size: 1.4rem;
  height: 5rem;
  justify-content: center;
}

.fiat-deposit-modal__title {
  font-size: 1.4rem;
  color: var(--font__color-primary);
  margin-bottom: 2rem;
}

.fiat-deposit-modal__close-icon {
  color: var(--font__color-primary);
  padding: 1.4rem 1.1rem;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .fiat-deposit-modal {
    min-width: 32rem;
    max-width: 32rem;
  }
}
