.send-address__input-wrapper {
  margin-bottom: 0;
}
.send-address__input-wrapper input:not([type='hidden']) {
  margin-bottom: 1rem;
}

.send-address__input {
  height: 4.4rem;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  background: var(--secondary__bg-color);
}

.send-address__label {
  color: var(--font__color-secondary);
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}

.send-address__label-in-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.2rem;
  width: 5rem;
  font-size: 1.4rem;
}

.send-address__with-label {
  padding-left: 6rem;
}

.send-address__error {
  padding: 3.7rem 3rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font__color-secondary);
}

.send-address__error .ap-icon--alert {
  width: 10%;
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.unavailable-note {
  background: #E91E63;
  padding: .5rem;
  text-align: center;
  margin: 1rem 0;
}

.send-form-selected-option {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}