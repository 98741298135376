.settings-page__container {
  display: flex;
  flex: 1 0 auto;
}

.settings-page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-page__menu {
  display: flex;
  flex-direction: column;
  background: var(--color-primary);
  /* border-right: var(--component__border);
  background: var(--secondary__bg-color); */
}

.settings-page__footer {
  flex-shrink: 0;
}

.nav-menu-vertical__item.active, .nav-menu-vertical__item:hover {
  background: var(--color-secondary)!important;
  border-left-color: #ffffff;
}

@media only screen and (min-width: 992px) {
  .user-settings-page__container {
    margin-left: 4rem;
  }
}

/* MEDIA QUERIES */

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .settings-page__container {
    flex-direction: column;
  }

  .settings-page__content {
    flex-direction: column;
    flex: 1 0 auto;
  }

  .profile-and-security-widget__layout-template {
    margin-left: var(--settings__body-header-margin);
    margin-right: var(--settings__body-header-margin)!important;
  }
}