.wallet-row {
  display: flex;
  min-width: 100%;
  width: 100%;
  min-height: 7rem;
  border-bottom: 1px solid var(--table__row-separator-color);
  background: var(--table__row-bg-color);
}

.wallet-row:hover {
  background: var(--wallet-row__bg-color--hover);
}

.wallet-row__icon-label {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 2% 1rem 2% 2%;
}

.wallet-row__symbol-label {
  margin-left: 1.5rem;
  font-size: 1.75rem;
  color: white !important;
}

.wallet-row__balance-columns {
  display: flex;
  align-items: center;
  width: 40%;
  padding: 0 2%;
}

.wallet-row__label {
  margin-bottom: 1rem;
  color: var(--font__color-secondary-light);
}

.wallet-row__amount {
  font-size: 1.5rem;
  color: var(--font__color-primary);
}

.wallet-row__amount-label {
  color: var(--font__color-secondary-light);
}

.wallet-row__activity-link-columns {
  display: flex;
  flex: 2.5
}

.wallet-row__activity-link {
  display: flex;
  align-items: center;
  width: 40%;
  padding: 1%;
  font-size: 1.4rem;
  border-left: 1px solid var(--wallet-card__divider-border-color-secondary);
}

a.wallet-row__activity-link {
  width: 100%;
  letter-spacing: 0.05rem;
  border: none;
  padding-left: 3rem;
  color: var(--color-secondary) !important;
}

.wallet-row__next-icon {
  margin-left: 0.75rem;
}

.wallet-row__icon-nav-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.5rem;
  width: 60%;
  background: var(--market-data-card-secondary-background);
}

.wallet-row__icon-nav-buttons > .wallet-nav-item {
  min-width: 4rem;
}

.wallet-row__icon-nav-buttons > :first-child {
  margin-left: 0;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .wallet-row__activity-link-columns {
    flex: 1.8;
  }
}

/* Mobile */
@media (min-width: 320px) and (max-width: 767px) {
  .wallet-row__activity-link-columns {
    flex: 1;
    justify-content: flex-end;
  }

  .wallet-row__icon-label {
    flex: 2;
    padding: 2.4rem 1rem 2.4rem 2.4rem;
  }

  .wallet-row__activity-link {
    border-left: none;
  }

  a.wallet-row__activity-link {
    padding-left: 0;
  }

  .wallet-row__icon-nav-buttons {
    padding-right: 1.2rem;
  }
}
